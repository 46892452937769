import React, { useState, useRef } from "react";
import { navigate } from "@reach/router";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas);

const Searchbar = ({ initialQuery = "" }) => {
	// Create a piece of state, and initialize it to initialQuery
	// query will hold the current value of the state,
	// and setQuery will let us change it
	const [query, setQuery] = useState(initialQuery);

	// We need to get reference to the search input element
	const inputEl = useRef(null);

	// On input change use the current value of the input field (e.target.value)
	// to update the state's query value
	const handleChange = (e) => {
		setQuery(e.target.value);
	};

	// When the form is submitted navigate to /search
	// with a query q paramenter equal to the value within the input search
	const handleSubmit = (e) => {
		e.preventDefault();
		// `inputEl.current` points to the mounted search input element
		const q = inputEl.current.value;
		navigate(`/search?q=${q}`);
	};

	return (
		<form role="search" onSubmit={handleSubmit}>
			<div className="flex flex-row w-full">
				<div className="flex flex-row border border-yellow-cust1 rounded-l-md w-2/3">
					<div className="bg-gray-100 rounded-l-md py-2 px-2">
						<span className="text-gray-500">
							<FontAwesomeIcon
								icon={["fas", "search"]}
								size="1x"
							/>
						</span>
					</div>
					<input
						ref={inputEl}
						className="bg-gray-100 focus:outline-none focus:shadow-outline border-0 py-2 pr-4 block w-full appearance-none leading-normal"
						type="search"
						placeholder="食材、類別"
						value={query}
						onChange={handleChange}
					></input>
				</div>
				<div className="border-2 border-yellow-cust1 rounded-r-md w-1/3">
					<button
						className="bg-yellow-cust1 hover:bg-yellow-700 text-black font-bold py-2 px-4 w-full text-center"
						type="submit"
					>
						搵食譜
					</button>
				</div>
			</div>
		</form>
	);
};

export default Searchbar;
